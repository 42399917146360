.page-header {
    &.is-inverted {
        color: theme('colors.white');
    }
    .menu-opening &,
    .menu-open & {
        color: currentColor;
    }
    &__bar {
        .menu-opening &,
        .menu-open &,
        .menu-closing & {
            position: fixed !important;
            transform: none !important;
        }
    }
}
