@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    .filter-button {
        @apply flex m:flex-wrap items-center justify-between h5-caps w-full m:w-auto px-8 m:px-10 py-6 m:py-7 rounded-20px border-1 mb-8 m:mr-8;
        @apply before:block before:w-[8px] before:h-[8px] l:before:w-[10px] l:before:h-[10px] before:rounded-8px before:border-1 m:before:mr-30;
        @apply hover:bg-yellow-light transition-colors duration-300 ease-out;
        @apply hover:before:bg-black before:transition-colors before:duration-300 before:ease-out;

        .bg-grey-darker & {
            @apply hover:bg-grey-dark before:bg-transparent hover:before:bg-white;
        }

        &.selected {
            @apply bg-yellow before:bg-black pointer-events-none;
            .bg-grey-darker & {
                @apply bg-transparent before:bg-yellow before:border-yellow;
            }
        }

        span {
            margin-bottom: -1px;
        }
    }

    .default-link, .wysiwyg a {
        @apply underline hover:decoration-transparent transition-colors duration-300 ease-out;
    }

    .inverted-link {
        @apply underline decoration-transparent hover:decoration-current transition-colors duration-300 ease-out;
    }

    body.js-cursor-hidden {
        *, *:before, *:after {
            cursor: none !important;
        }
    }
}
