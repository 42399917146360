.wysiwyg {
    p, ul, ol {
        margin-bottom: calc((22/16) * 1em);

        &:last-child {
            margin-bottom: 0;
        }
    }

    i, em {
        @apply italic;
    }

    b, strong {
        @apply font-bold;
    }

    h2, h3 {
        @apply h4;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }

    ul {
        padding-left: 1em;
        list-style: disc;
    }

    li {
        display: list-item;
        margin-bottom: 0.25em;
        &:last-child {
            margin-bottom: 0;
        }
    }

    ol {
        list-style: none;
        counter-reset: ol-counter;
        li {
            counter-increment: ol-counter;
            display: flex;
            &:before {
                content: counter(ol-counter) ".";
                margin-right: 0.5em;
            }
        }
    }
}
