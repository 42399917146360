:root {
    // Hotfix for Tailwind 3's transform utilities being buggy in Safari <=14
    // https://github.com/tailwindlabs/tailwindcss/discussions/8788
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
}

:root {
    --header-height: 110px;
    @screen l {
        --header-height: 145px;
    }
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    transition: opacity 0.6s theme('transitionTimingFunction.out-quad');
}

.lazyload.lazyzoom, .lazyloading.lazyzoom {
    transform: scale(1.03, 1.03);// translate(0, 2vh);
}

.lazyloaded.lazyzoom {
    transform: scale(1, 1);// translate(0, 0);
    transition: opacity 0.5s theme('transitionTimingFunction.in-sine'), transform 1s theme('transitionTimingFunction.out-quad');
}

.no-js img.lazyload[data-src],
.no-js img.lazyload[data-srcset] {
    display: none;
}

html.outline,
html.no-outline * {
    outline: none !important;
}

svg {
    display: block;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid:not(.blowout) {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button {
    appearance: none;
    font-weight: normal;
    text-align: left;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none !important;
}

select {
    appearance: none;
}

input[class*="appearance-none"] {
    -webkit-appearance: none;
}

table {
    th, td {
        text-align: left;
        padding: 0;
        font-weight: normal;
        vertical-align: top;
    }
}

strong, b {
    font-weight: normal;
}

i, em {
    font-style: italic;
}

.hit::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

img, video, svg {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

svg {
    display: block;
    width: 100%;
}

a, button {
    touch-action: manipulation;
}

details > summary {
    list-style: none;
    cursor: pointer;
}

details > summary > * {
    display: inline;
}

details > summary::-webkit-details-marker {
    display: none;
}

.vignette {
    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    @supports selector(:has(+ *)) {
        &::before {
            opacity: 0;
            transition: opacity 0.5s theme('transitionTimingFunction.in-sine');
        }
        &:has(.lazyloaded)::before {
            opacity: 1;
        }
    }
}

// Reveals
html.js:not(.js-error):not(.is-preview.is-iframe) [data-reveal]:not([data-revealed]) {
    opacity: 0.001;
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        transform: none !important;
        opacity: 1 !important;
    }
}

body {
    transition: background-color 1s;
}

html.js [data-color] {
    background-color: transparent;
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
    body {
        *, *:before, *:after {
            animation: none !important;
            transition: none !important;
        }
    }
}
